import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { Badge, Button, Card, Col, ListGroup, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { useGetOrderDetailsQuery, usePayOrdersMutation } from '../../slices/ordersApiSlice';
import OrderDetailOperationCard from './OrderDetailOperationCard';

const OrderDetailScreen = () => {
	const { id: orderId } = useParams();
	const { userInfo } = useSelector((state) => state.auth);
	const { data: order, refetch, isLoading, error } = useGetOrderDetailsQuery(orderId);
	const navigate = useNavigate();
	const [payOrder, { isLoading: loadingPay }] = usePayOrdersMutation();

	const [placeOrderNode, setPlaceOrderNode] = useState([]);
	const [deliverNode, setDeliverNode] = useState([]);
	const [acceptNode, setAcceptNode] = useState([]);

	useMemo(() => {
		setPlaceOrderNode(order?.accountsReceivables.filter((node) => node.title === '预付'));
		setDeliverNode(order?.accountsReceivables.filter((node) => node.title === '发货'));
		setAcceptNode(order?.accountsReceivables.filter((node) => node.title === '验收'));
	}, [order?.accountsReceivables]);

	async function onPayOrder(accountsReceivable, title) {
		await payOrder({ orderId, details: { payAccountsReceivable: accountsReceivable, title } });
		refetch();
		toast.success('客户已支付货款!');
	}

	return isLoading ? (
		<Loader />
	) : error ? (
		<Message variant='danger'></Message>
	) : (
		<>
			<Row className='g-3'>
				<Col xs={12}>
					<Card className='mb-1'>
						<Card.Body>
							<Row className='flex-between-center'>
								<Col md>
									<h5 className='my-2 mb-md-0 me-1'>
										订单编号 # {order.orderId}
										{order.approvalStatus === 'inProcessOfApproval' && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle ' bg='info'>
												<FontAwesomeIcon icon='fa-rotate' spin spinReverse className='me-1' />
												<span>待审批</span>
											</Badge>
										)}
										{order.approvalStatus === 'approved' && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='success'>
												<FontAwesomeIcon icon='check' className='me-1' />
												<span>审批通过</span>
											</Badge>
										)}
										{order.approvalStatus === 'reject' && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='danger'>
												<FontAwesomeIcon icon='xmark' className='me-1' />
												<span>被驳回</span>
											</Badge>
										)}
									</h5>
								</Col>
								<Col xs='auto'>
									<Button variant='outline-primary' className='me-2' onClick={() => navigate(`/${userInfo.role}/orderlist`)}>
										<FontAwesomeIcon icon='list' className='me-2' />
										<span>订单列表</span>
									</Button>
									<Button variant='outline-primary' onClick={() => navigate('/dashboard')}>
										<FontAwesomeIcon icon='home' className='me-2' />
										<span>首页</span>
									</Button>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col md={8}>
					<Card>
						<Card.Header>
							<Card.Title className='mb-0'>订单详情</Card.Title>
						</Card.Header>
						<Row>
							<Col>
								<ListGroup variant='flush'>
									<ListGroup.Item>
										<Row>
											<Col>
												<p>
													<strong>客户名称: </strong>
													{order.customerInfo.customerName}
												</p>
												{order.customerInfo.staffName && (
													<p>
														<strong>联系人: </strong>
														{order.customerInfo.staffName}
													</p>
												)}
												{order.customerInfo.staffPhone && (
													<p>
														<strong>电话: </strong>
														{order.customerInfo.staffPhone}
													</p>
												)}
												{order.customerInfo.staffWechat && (
													<p>
														<strong>微信: </strong>
														{order.customerInfo.staffWechat}
													</p>
												)}
												{order.customerInfo.staffEmail && (
													<p>
														<strong>邮箱: </strong>
														<a href={`mailto:${order.customerInfo.staffEmail}`}>{order.customerInfo.staffEmail}</a>
													</p>
												)}
												<p>
													<strong>地址: </strong>
													{order.customerInfo.customerAddress}
												</p>
											</Col>
											<Col>
												<ListGroup variant='flush'>
													<ListGroup.Item key={order.orderId}>
														<p>
															<strong>业务联系人: </strong>
															{order.saleUser.name}
														</p>
														{order.saleUser.phone && (
															<p>
																<strong>电话: </strong>
																{order.saleUser.phone}
															</p>
														)}
														{order.saleUser.email && (
															<p>
																<strong>邮箱: </strong>
																<a href={`mailto:${order.saleUser.email}`}>{order.saleUser.email}</a>
															</p>
														)}
													</ListGroup.Item>
													<ListGroup.Item key={order.orderId}>
														<p>
															<strong>销售助理: </strong>
															{order.assistantUser.name}
														</p>
														{order.assistantUser.phone && (
															<p>
																<strong>电话: </strong>
																{order.assistantUser.phone}
															</p>
														)}
														{order.assistantUser.email && (
															<p>
																<strong>邮箱: </strong>
																<a href={`mailto:${order.assistantUser.email}`}>{order.assistantUser.email}</a>
															</p>
														)}
													</ListGroup.Item>
												</ListGroup>
											</Col>
										</Row>
									</ListGroup.Item>

									<ListGroup.Item>
										<h5 className='my-3'>
											<strong>
												订单备注 <FontAwesomeIcon icon='fa-regular fa-comment-dots' className='text-primary' />
											</strong>
										</h5>
										<Row>
											<Col>
												{order.orderNotes !== '' ? (
													<p>
														<FontAwesomeIcon icon='fa-solid fa-quote-left' className='text-primary' />
														&nbsp;&nbsp;{order.orderNotes}&nbsp;&nbsp;
														<FontAwesomeIcon icon='fa-solid fa-quote-right' className='text-primary' />
													</p>
												) : (
													<p>无订单备注</p>
												)}
											</Col>
										</Row>
									</ListGroup.Item>

									<ListGroup.Item>
										<h5 className='my-3'>
											<strong>货款支付</strong>
										</h5>
										<p>
											<strong>方式: </strong>
											{order.paymentMethod}
										</p>

										<Row className='mb-2'>
											<Col>产品总价</Col>
											<Col>¥ {order.itemsPrice}</Col>
										</Row>
										<Row className='mb-2'>
											<Col>其他费用</Col>
											<Col>¥ {order.deliveryPrice}</Col>
										</Row>
										{order.paymentMethod === '部分支付' && order.prePaymentOption === '百分比' && (
											<>
												<Row className='mb-2'>
													<Col>预付百分比:</Col>
													<Col>
														<strong>{order.prePaymentPercent} %</strong>
													</Col>
												</Row>
												<Row className='mb-2'>
													<Col>预付金额:</Col>
													<Col>
														<strong>¥ {order.percentPayment} </strong>
													</Col>
												</Row>
												<Row className='mb-2'>
													<Col>剩余尾款:</Col>
													<Col>
														<strong>¥ {order.percentBalance}</strong>
													</Col>
												</Row>
											</>
										)}
										{order.paymentMethod === '部分支付' && order.prePaymentOption === '预付款' && (
											<>
												<Row className='mb-2'>
													<Col>预支付:</Col>
													<Col>
														<strong>¥ {order.prePayment}</strong>
													</Col>
												</Row>
												<Row className='mb-2'>
													<Col>剩余尾款:</Col>
													<Col>
														<strong>¥ {order.prePaymentBalance}</strong>
													</Col>
												</Row>
											</>
										)}
										<Row className='mb-3'>
											<Col>
												<strong>总价</strong>
											</Col>
											<Col>
												<strong>¥ {order.totalPrice}</strong>
											</Col>
										</Row>
										{loadingPay && <Spinner size='sm' />}
										{order.accountsReceivables.length !== 0 &&
											order.accountsReceivables.map((ar) => (
												<>
													<Message variant={!ar.isPaid ? 'warning' : 'success'}>
														<FontAwesomeIcon icon='fa-coins' className='me-2' />
														订单{ar.title}应收账款 共计 {ar.accountsReceivable} 元
														{userInfo.role === 'finance' && !ar.isPaid && ['approved', 'systemeApproved'].includes(order.approvalStatus) && (
															<Button type='button' className='btn btn-block my-3 ms-2' onClick={() => onPayOrder(ar.accountsReceivable, ar.title)}>
																<FontAwesomeIcon icon='fa-solid fa-stamp' className='me-2' />
																客户已支付{ar.title}款
															</Button>
														)}
													</Message>
												</>
											))}
									</ListGroup.Item>
									<ListGroup.Item className='mb-5'>
										<h5 className='my-3'>
											<strong>订单列表</strong>
										</h5>
										<ListGroup.Item className='bg-primary'>
											<Row className='text-white'>
												<Col>
													<strong>货号</strong>
												</Col>
												<Col>
													<strong>设备名称</strong>
												</Col>
												<Col>
													<strong>数量</strong>
												</Col>
												<Col>
													<strong>单价</strong>
												</Col>
												<Col>
													<strong>小计</strong>
												</Col>
											</Row>
										</ListGroup.Item>
										{order.orderItems.map((item, index) => (
											<>
												<ListGroup.Item key={index} variant={item.isDiscount && 'danger'} data-bs-theme='light' as={Link} to='#!' action>
													<Row>
														<Col>
															{item.productCode}
															{item.isDiscount && <FontAwesomeIcon icon='star' className='text-danger fs-10 ms-1' />}
														</Col>
														<Col>{item.name}</Col>
														<Col>{item.qty}</Col>
														<Col>¥{item.sellingPrice}</Col>
														<Col>¥{(item.qty * item.sellingPrice).toFixed(2)}</Col>
													</Row>
												</ListGroup.Item>
											</>
										))}
									</ListGroup.Item>
								</ListGroup>
							</Col>
						</Row>
					</Card>
				</Col>

				<Col md={4}>
					<OrderDetailOperationCard order={order} refetch={refetch} placeOrderNode={placeOrderNode} deliverNode={deliverNode} acceptNode={acceptNode} />
				</Col>
			</Row>
		</>
	);
};

export default OrderDetailScreen;
